.react-colorful {
  width: 100% !important;
  gap: 12px;
}

.react-colorful > .react-colorful__saturation {
  border-radius: 4px;
}

.react-colorful > .react-colorful__last-control {
  border-radius: 4px;
  height: 12px;
}
