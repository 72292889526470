.editor-root {
  resize: both;
  border: 1px solid #ccc;
  border-radius: 4px;
  min-height: 200px;
  padding: 8px;
  overflow: auto;
}

.editor-root > * {
  margin: 0 0 8px;
}

.textBold {
  font-weight: bold;
}

.textItalic {
  font-style: italic;
}

h1 {
  font-size: 2em;
}

.react-colorful {
  gap: 12px;
  width: 100% !important;
}

.react-colorful > .react-colorful__saturation {
  border-radius: 4px;
}

.react-colorful > .react-colorful__last-control {
  border-radius: 4px;
  height: 12px;
}
/*# sourceMappingURL=Admin.edde9d7b.css.map */
